<template>
  <div id="contestWorks">
    <div class="contestWorks-container">
      <div class="bread-title">
        <div class="list-name" @click="getAllList">
          全部作品
        </div>
        <ul class="tabs">
          <el-select v-model="groupId" @change="(val) => { tabChange(val, 'cate1') }" placeholder="参赛组别"
            class="works-type" style="width: 180px; margin-right: 10px">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="typeId" @change="(val) => { tabChange(val, 'cate2') }" placeholder="作品类型" class="works-type"
            style="width: 180px; margin-right: 10px">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </ul>
        <div class="search-box">
          <div class="icon-box" @click="getList(1)">
            <i class="el-icon-search"></i>
          </div>
          <div class="input-box">
            <input v-model="name" placeholder="搜索" @keyup.enter="getList(1)" />
          </div>
        </div>
        <div class="sort">
          排序方式：<span @click="tabChange('1', 'sort')" :class="{ active: sortActive == 1 }">时间</span>
          <i> | </i>
          <span @click="tabChange('2', 'sort')" :class="{ active: sortActive == 2 }">浏览</span>
        </div>
      </div>
      <div class="bar"></div>
      <div class="works-box">
        <div class="works-item" v-for="(item, index) in list.workList" :key="index" @click="goDetail(item)">
          <div class="works-item-cover" :style="{
            backgroundImage:
              'url(' +
              item.cover +
              '?x-oss-process=image/resize,m_mfit,w_200,h_165)',
          }"></div>
          <div class="works-item-bottom">
            <div class="work-item-name">{{ item.name }}</div>
            <div class="work-item-author">{{ item.authorName }}</div>
            <div class="work-item-detail-box">
              <div class="work-item-cate">
                <img class="cate-icon" src="../assets/img/cate-icon.png" alt="">
                <span style="vertical-align: middle">{{ typeList[item.category - 1].name }}</span>
              </div>
              <div class="work-item-createtime">
                <img class="time-icon" src="../assets/img/time-icon.png" alt="">
                <span style="vertical-align: middle">{{ formatDate(item.createTime) }}</span>
              </div>
              <div class="work-item-views">
                <img class="view-icon" src="../assets/img/view-icon.png" alt="">
                <span style="vertical-align: middle">{{ item.viewCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination v-if="pageInfo.total > 12" @current-change="getList" :currentPage="pageInfo.pageNum" background
          :page-size="pageInfo.pageSize" layout=" prev, pager, next" :total="pageInfo.total" style="text-aligin: right">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { listByPage } from "../utils/apis/worksApi";
import debounce from "../utils/debounce"

export default {
  name: "contestWorks",
  setup() {
    const groupId = ref(1)
    const typeId = ref(1)
    const sortActive = ref(1);
    const route = useRoute();
    const router = useRouter();
    const name = ref("");
    const pageInfo = reactive({
      pageSize: 15,
      pageNum: 1,
      total: 13,
    });
    const groupList = [
      {
        name: "专业组",
        id: 1
      },
      {
        name: "高校组",
        id: 2
      }
    ]
    const typeList = [
      {
        name: "设计类",
        id: 1
      },
      {
        name: "Ani动画类",
        id: 2
      },
      {
        name: "CG类",
        id: 3
      }
    ]
    const list = reactive({
      workList: [],
    });

    watch(
      () => route.name,
      (val) => {
        //重置
        if (val === "contestWorks") {
          groupId.value = null
          typeId.value = null
          sortActive.value = 1;
          pageInfo.pageNum = 1;
          pageInfo.total = 13;
          list.workList = [];
          getList(1);
        }
      }
    );

    const coverImage = ref(
      "https://cyc-test.oss-cn-shanghai.aliyuncs.com/design-competition/2021-04-23/16191407496701618967035552.jpg"
    );
    const getAllList = debounce(() => { tabChange(null, 'all') }, 500)
    function tabChange(key, category) {
      if (category == "all") {
        groupId.value = key;
        typeId.value = key;
      }
      else if (category == "cate1") {
        groupId.value = key;
      } else if (category == "cate2") {
        typeId.value = key;
      }
      else {
        sortActive.value = key;
      }
      getList(1);
    }

    getList(1);

    function getList(value) {
      pageInfo.pageNum = value;
      let params = {
        category: typeId.value,
        pageNum: pageInfo.pageNum,
        pageSize: pageInfo.pageSize,
        sortRule: sortActive.value,
        name: name.value,
        competitionUnit: groupId.value,
      };

      listByPage(params).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          list.workList = res.data.data.list;
          pageInfo.total = res.data.data.total;
        }
      });
    }

    function goDetail(item) {
      if (item.category == 1 || item.category == 3) {
        //主题详情 -平面
        router.push({
          path: "/contestWorks/imageWorkDetail",
          query: { id: item.id, type: item.competitionUnit, cate: item.category, views: item.viewCount },
        });
      } else {
        //主题详情 -动画
        router.push({
          path: "/contestWorks/videoWorkDetail",
          query: { id: item.id, type: item.competitionUnit, cate: item.category, views: item.viewCount },
        });
      }

    }

    function formatDate(date) {
      let d = new Date(date)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }

    return {
      getAllList,
      tabChange,
      sortActive,
      coverImage,
      route,
      router,
      pageInfo,
      getList,
      list,
      goDetail,
      name,
      formatDate,
      groupList,
      typeList,
      groupId,
      typeId,
    };
  },
};
</script>
<style lang="less" scoped>
#contestWorks {
  background-image: url("../assets/img/bg3.png");
  background-size: cover;
  background-position: center;
  padding: 80px 0 120px 0;

  .contestWorks-container {
    max-width: 1480px;
    min-width: 1480px;
    margin: 0 auto;
    padding: 35px 40px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }

  .search-box {
    float: left;
    width: 250px;
    height: 40px;
    display: flex;
    line-height: 40px;
    border-radius: 20px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    padding-left: 15px;
    margin-left: 30px;
    // transform: translateY(6px);

    .icon-box {
      font-size: 22px;
      cursor: pointer;
      color: #dcdfe6;
    }

    .input-box {
      font-size: 0px;
      height: 40px;
      padding-left: 10px;
    }

    .input-box input {
      border: none;
      box-sizing: border-box;
      height: 100%;
      outline: none;
      color: #909399;
      width: 170px;
      background-color: inherit;
    }
  }

  .bread-title {
    height: 45px;
    line-height: 40px;
    overflow: hidden;
  }

  .list-name {
    float: left;
    width: 110px;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 20px;
    background-color: #325888;
    color: #fff;
    text-align: center;
    line-height: 40px;
    margin-right: 20px;
    cursor: pointer;
  }

  .tabs {
    float: left;

    ::v-deep .el-select {
      width: 130px !important;
      height: 40px;
      margin-right: 20px !important;

      .el-input__inner {
        width: 130px !important;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        background-color: #325888;
      }
    }
  }

  .sort {
    float: right;
    color: #303133;

    i {
      font-style: normal;
    }

    span {
      cursor: pointer;
      color: #909399;
    }

    span:hover {
      color: #003588;
      font-weight: bold;
    }

    span.active {
      color: #003588;
      font-weight: bold;
    }
  }

  .bar {
    width: 1400px;
    border: 1px solid #ABBFD8;
    margin-top: 22px;
    margin-bottom: 39px;
  }

  .works-box {
    min-height: 600px;
    margin-left: -24px;
    display: flex;
    flex-wrap: wrap;

    .works-item {
      margin-left: 24px;
      width: 260px;
      height: 275px;
      margin-bottom: 25px;
      background-color: #fff;
      overflow: hidden;
      border: 1px solid #fff;
      box-sizing: border-box;

      .works-item-cover {
        height: 180px;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
      }
    }

    .works-item-bottom {
      height: 89px;
      padding-top: 15px;
      box-sizing: border-box;

      .work-item-name {
        color: #333333;
        font-size: 16px;
        height: 16px;
        line-height: 16px;
        box-sizing: border-box;
        margin-bottom: 12px;
      }

      .work-item-author {
        font-size: 16px;
        height: 16px;
        line-height: 16px;
        text-align: left;
        color: #333333;
        margin-bottom: 17px;
      }

      .work-item-detail-box {
        height: 14px;
        height: 14px;
        line-height: 14px;
        position: relative;

        .work-item-cate,
        .work-item-createtime,
        .work-item-views {
          display: inline-block;
          color: #909399;
          font-size: 14px;
          margin-right: 11px;

          &>img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }

          .cate-icon {
            width: 12px;
            height: 12px;
          }

          .time-icon {
            width: 12px;
            height: 12px;
          }

          .view-icon {
            width: 19px;
            height: 12px;
          }
        }

        .work-item-createtime {
          margin-right: 12px;
        }

        .work-item-views {}
      }
    }

    .works-item:hover {
      border-color: #013589;
      cursor: pointer;
    }
  }

  .pagination {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 85px;
  }

  .pagination /deep/ .el-pager li {
    background-color: #f7f7f7;
  }

  .pagination /deep/ .el-pager li.active {
    color: #2397ff;
  }

  .pagination /deep/ .el-pagination .btn-next,
  .pagination /deep/ .el-pagination .btn-prev {
    background-color: #f7f7f7;
  }
}
</style>
